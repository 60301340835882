/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* --------------------------- */ 


/* Coolors Exported Palette - coolors.co/d9f0ff-a3d5ff-83c9f4-6f73d2-7681b3 */

/* RGB */
$dark-grey: rgba(42, 45, 52, 1);
$primary-blue: rgba(102, 153, 204, 1);
$light-blue: rgba(153, 204, 232, 1);
$dark-blue: rgba(76, 96, 133, 1);
$red: rgb(204, 41, 54);

// Start Meainingful CSS

body {
  color: $dark-grey;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: $dark-grey;
  transition: color 200ms;
  &:visited {
    color: $dark-grey;
  }
  &:hover {
    color: $red;
  }
}

main {
  background-color: $primary-blue;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100vh;
}

.intro {
  padding: 1em 3em;
}

header {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  margin: 20px;
  align-items: center;
  img {
    display: block;
    align-self: center;
    border-radius: 50%;
    border: 6px solid $light-blue;
    margin: 5px;
    max-width: 240px;
  }
  .name {
    margin: 10px;
    font-size: 4rem;
    font-weight: 700;
  }
  .title {
    color: $dark-grey;
    font-size: 1.8rem;
    font-weight: 500;
    transition: color 200ms;
    &:hover {
      cursor: pointer;
      color: $red;
    }
  }
}

.portfolio {
  font-size: 1.2rem;
  color: #DDD;
  background-color: hsla(210, 100%, 0%, 0.4);
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  .project-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 24px;
    a {
      color: #DDD;
      &:hover {
        color: $red;
      }
    }
    .project {
      margin: 0 10px;
    }
  }
}

.links {
  font-size: 2.8rem;
  justify-content: center;
  margin: 20px;
  svg {
    margin: 0 10px;
    color: $dark-grey;
    &:hover {
      color: $red;
    }
  }
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  margin: 2rem;
}
h2 {
  font-size: 2rem;
  font-weight: 600;
  margin: 1rem;
}

input {
  height: 1.6rem;
}

.chevron-down {
  margin: 50px 0 0 0;
  font-size: 2em;
}

@media (max-width: 500px) {
  .portfolio {
    display: none;
  }
}