form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 20px;

  input {
    grid-column: 1 / 3;
    justify-self: center;
    grid-row: 2;
  }

  label {
    justify-self: center;
    font-size: 16pt;
  }

  #secret-label {
    grid-column: 1 / 3;
    grid-row: 1;
  }

  #input-label {
    grid-row: 3;
  }

  #output-label {
    grid-row: 3;
  }

  #input {
    grid-column: 1;
    grid-row: 4;
  }

  #output {
    grid-column: 2;
    grid-row: 4;
  }
}